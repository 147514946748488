import { useState, useEffect } from "react";

export const useGeoLocation = () => {
    const [location, setLocation] = useState(null);
    const GEOLOCATION_URL = process.env.GEOLOCATION_URL; //ipapi.co provides a REST API to find the location of an IP address.

    useEffect(() => {
        let isApiSubscribed = true;

        fetch(GEOLOCATION_URL)
            .then(data => data.json())
            .then(json => {
                if (isApiSubscribed) {
                    setLocation(json);
                }
            })
            .catch(err => {});
        return () => {
            isApiSubscribed = false;
        };
    }, [GEOLOCATION_URL]);

    return {
        location
    };
};
